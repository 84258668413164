import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, initReactI18next } from "react-i18next";
import { Col, Row, Button } from "reactstrap";
import i18n from '../../i18n';
import { Trans } from "react-i18next";
import { isNotNullUndefinedOrEmpty, isNullUndefinedOrEmpty } from '../../utilities';
import Timeline from '../Timeline/Timeline';

//import './footer.scss';

import EEANorwayLogo from '../../images/EEA-and-Norway_grants_White.png'

const Footer = (props) => {
	const { t, i18n } = useTranslation();

	return (
		<footer className="Footer">
			<Timeline year={ props.year } />
			<div className="LearningResources">
				<ul>
					<li><a href={`/resources/${ i18n.language == 'nb-NO' ? "nb-NO/OmNettressursen.pdf" : "en/Aboutthewebresource.pdf" }`} target="_blank"><Trans>About the web resource</Trans></a></li>
					<li><a href={`/resources/${ i18n.language == 'nb-NO' ? "nb-NO/VeiledningForLaerere.pdf" : "en/TeacherGuide.pdf" }`} target="_blank"><Trans>Teacher guide</Trans></a></li>
					<li><a href={`/resources/${ i18n.language == 'nb-NO' ? "nb-NO/Arbeidsoppgaver.pdf" : "en/Exercises.pdf" }`} target="_blank"><Trans>Exercises</Trans></a></li>
					<li><a href="https://www.fanger.no/persons/30709" target="_blank"><Trans>Josef Grabowski on fanger.no</Trans></a></li>
				</ul>
			</div>
			<div id="partner-logos">
				This website is partly funded by the EEA Grants and Norway Grants 
				<img src={EEANorwayLogo} alt="EEA and Norway grants logo" width="200" />
			</div>
		</footer>
	);
};

Footer.defaultProps = {
}

Footer.propTypes = {
}

export default Footer;